<template>
  <div>
    <hr />
    <center>
      <h1>{{ h1 }}</h1>
    </center>
    <hr />
    <section v-if="!spiner" class="box1 container-lg box-center">
      <div
        style="text-align: center"
        v-if="erreur"
        class="alert alert-danger"
        role="alert"
      >
        {{ msg }}
      </div>
      <div
        style="text-align: center"
        v-else-if="msg2 !== ''"
        class="alert alert-success"
        role="alert"
      >
        {{ msg2 }}
      </div>
      <form class="xform">
        <div class="form-row">
          <div class="col-md mb-2">
            <label for="validationDefault01">Titre</label>
            <input
              v-model="titre"
              type="text"
              class="form-control"
              id="validationDefault01"
              placeholder="Titre"
              required
            />
          </div>
          <div class="col-md mb-2">
            <label for="validationDefault02">Lieu</label>
            <input
              v-model="lieu"
              type="text"
              class="form-control"
              id="validationDefault02"
              placeholder="Lieu"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="col-md mb-2">
            <label for="validationDefault02">Début</label>
            <input
              v-model="date_debut"
              type="date"
              class="form-control"
              id="validationDefault02"
              placeholder="Date du début"
            />
          </div>
          <div class="col-md mb-2">
            <label for="validationDefault02">Fin</label>
            <input
              v-model="date_fin"
              type="date"
              class="form-control"
              id="validationDefault02"
              placeholder="Date de fin"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="col-md mb-2">
            <label for="validationDefault02">Module</label>
            <input
              v-model="module"
              type="text"
              class="form-control"
              id="validationDefault02"
              placeholder=""
            />
          </div>
          <div class="col-md mb-2">
            <label for="validationDefault02">Nombre de participant</label>
            <input
              v-model="participant"
              type="text"
              class="form-control"
              id="validationDefault02"
              placeholder=""
            />
          </div>
        </div>
        <div class="form-row">
          <div class="col-md mb-2">
            <label for="validationDefault02">Fréquence</label>
            <input
              v-model="frequence"
              type="text"
              class="form-control"
              id="validationDefault02"
              placeholder="Frequence"
            />
          </div>
          <div class="col-md mb-2">
            <label for="validationDefault02">Observation</label>
            <input
              v-model="observation"
              type="text"
              class="form-control"
              id="validationDefault02"
              placeholder=""
            />
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md mb-2">
            <label for="exampleFormControlTextarea1">Description</label>
            <textarea
              v-model="description"
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
            ></textarea>
          </div>
        </div>

        <button
          @click="envoyer()"
          class="btn float-right btn-primary"
          type="submit"
        >
          Enregistrer</button
        ><br /><br />
      </form>
    </section>
    <div v-else class="chargement">
      <div class="spinner-grow text-info" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-info" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-info" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { tabElemen } from "./l_formation.vue";
export default {
  name: "Formation",
  data: () => {
    return {
      photo: "",
      titre: "",
      lien: "",
      lieu: "",
      frequence: "",
      observation: "",
      participant: "0",
      date_debut: "",
      date_fin: "",
      module: "",
      TabElement: tabElemen,
      mod: false,
      Etatdeschamps: "",
      description: "",
      importation: false,
      h1: "Nouvelle Formation",
      msg: "",
      erreur: false,
      spiner: false,
      msg2: "",
      reponx_code: "",
    };
  },
  methods: {
    firstElement: function () {
      this.selectitem = this.reponx[0];
    },
    ActiverChamps: function () {
      var champ = document.getElementById("champsD");
      champ.disabled = !champ.disabled;
    },
    envoyer: async function () {
      this.spiner = true;
      const FORMATION = new FormData();
      if (this.importation && this.TabElement) {
        FORMATION.set("id", this.TabElement.id);
      }
      FORMATION.set("titre", this.titre);
      FORMATION.set("description", this.description);
      FORMATION.set("module", this.module);
      FORMATION.set("date_debut", this.date_debut);
      FORMATION.set("lieu", this.lieu);
      FORMATION.set("frequence", this.frequence);
      FORMATION.set("observation", this.observation);
      FORMATION.set("nbr_participants", this.participant);
      FORMATION.set("date_fin", this.date_fin);
      axios
        .post(
          "https://www.rab-tech.com/dev_mode/js/api/save_formation.php",
          FORMATION
        )
        .then((response) => {
          console.log(response);
          this.reponx_code = response.data.code;
        })
        .catch((error) => {
          console.log(error);
          this.msg = "Echec de l'enregistrement. Veillez réessayer svp !";
          this.erreur = true;
          this.spiner = false;
        })
        .finally(() => {
          //this.$router.push({path: '/Formation-liste'})
          if (this.reponx_code == "201") {
            this.spiner = false;
            this.msg2 = "Enregistrement réussit.";
            this.erreur = false;
            this.titre = "";
            this.description = "";
            this.module = "";
            this.date_debut = "";
            this.lieu = "";
            this.frequence = "";
            this.observation = "";
            this.participant = "";
            this.date_fin = "";
          } else {
            this.msg = "Echec de l'enregistrement. Veillez réessayer svp !";
            this.erreur = true;
            this.spiner = false;
          }
        });
    },
    verifierModification: function () {
      let lien = document.URL;
      let lien2 = lien.split("/").pop();
      if (this.TabElement && lien2 == "1") {
        this.importation = true;
        if (this.TabElement.titre) {
          this.h1 = this.TabElement.titre;
        }
        if (this.TabElement.titre) {
          this.titre = this.TabElement.titre;
        }
        if (this.TabElement.module) {
          this.module = this.TabElement.module;
        }
        if (this.TabElement.date_debut) {
          this.date_debut = this.TabElement.date_debut;
        }
        if (this.TabElement.date_fin) {
          this.date_fin = this.TabElement.date_fin;
        }
        if (this.TabElement.lieu) {
          this.lieu = this.TabElement.lieu;
        }
        if (this.TabElement.frequence) {
          this.frequence = this.TabElement.frequence;
        }
        if (this.TabElement.observation) {
          this.observation = this.TabElement.observation;
        }
        if (this.TabElement.nbr_participants) {
          this.participant = this.TabElement.nbr_participants;
        }
        if (this.TabElement.description) {
          this.description = this.TabElement.description;
        }
      }
    },
  },
  created() {
    this.verifierModification();
  },
};
</script>

