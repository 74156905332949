<template>
  <div class="formation">
    <Formation/>
    
  </div>
</template>

<script>
// @ is an alias to /src

import Formation from '../components/Formation.vue'

export default {
  name: 'Formations',
  components: {
    Formation,
  }
}
</script>
